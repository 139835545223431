import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";
import useGetProductDetails from "../../hooks/products/useGetProductDetails";
import Price from "./Price";
import OptionForm from "./OptionForm";
import RealtedProducts from "../../sections/product/RelatedProducts";
import VideoSection from "../../sections/product/VideoSection";
import ReviewSection from "../../sections/product/ReviewSection";
import useGetProductOptions from "../../hooks/products/useGetProductOptions";
import { isUserLoggedIn } from "../../utils/auth";
import { PriceSkuContext } from "../../context/PriceSkuContext";
import useCustomerGroupRestriction from "../../hooks/customer/useCustomerGroupRestriction";
import QuestionSection from "../../sections/product/AskQuestionSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPrint } from "@fortawesome/free-solid-svg-icons";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import ProductsList from "../../sections/product/ProductsList";
import useGetPopularProducts from "../../hooks/products/useGetPopularProducts";
import { Link, navigate } from "gatsby";
import DescriptionSection from "../../sections/product/DescriptionSection";
import Loader from "../form/Loader";

function ProductDetailsUnregisterRoute({ location, product_id }) {
  const user = isUserLoggedIn();

  const { productDetails, isLoading } = useGetProductDetails(product_id, '1', user);
  const { product_variants } = useGetProductOptions(product_id, user);

  const { lineItemsLength } = useSelector((state) => state.cart);
  const { currentLoginUser } = useSelector((state) => state.customer);

  const [reviewImage, setReviewImage] = useState({});
  const reviewSectionRef = useRef(null);
  const [activateReview, setActivateReview] = useState(false);

  const { isCustomerRestricted } = useCustomerGroupRestriction();
  const { popularProducts } = useGetPopularProducts();

  const {
    sku,
    brand_name,
    brand_url,
    name,
    base_price,
    retail_price,
    sale_price,
    images,
    custom_fields,
    upc,
    order_quantity_minimum,
    order_quantity_maximum,
    weight,
    availability,
    description,
    warranty,
    reviews_count,
    reviews_rating_sum,
    videos,
    related_products,
    breadcrumb_url,
    bulk_pricing_rules,
    isVisible
  } = productDetails;

  const handlePrint = () => {
    window.print();
  };

  // conditionally redirect to 403 page if product is not visible
  useEffect(() => {
    if (isVisible === false) {
      navigate("/403");
    }
  }, [isVisible]);

  const [priceSku, setPriceSku] = useState({
    sku: sku || "",
    base_price: base_price,
    retail_price: retail_price,
    sale_price: sale_price,
    variantId: null,
  });

  useEffect(() => {
    if (base_price || retail_price || sale_price || sku) {
      setPriceSku((prevState) => ({
        ...prevState,
        base_price: base_price,
        retail_price: retail_price,
        sale_price: sale_price,
      }));
    }
  }, [base_price, retail_price, sale_price, sku]);

  useEffect(() => {
    if (priceSku?.variantId !== null) {
      setPriceSku((prevState) => ({
        ...prevState,
        base_price: priceSku?.base_price,
        retail_price: priceSku?.retail_price,
        sale_price: priceSku?.sale_price
      }));
    }
  }, [priceSku.variantId]);

  const contextValue = {
    setPriceSku,
    priceSku,
  };

  return (
    <div className="page-wrapper">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="product-detail-wrap">
          <div className="container">
            <Breadcrumbs location={location} url={breadcrumb_url} />
          </div>
          <section className="product-view-section">
            <div className="container">
              <div className="bc-product-single">
                <div className="row flex flex-wrap vertical-top product-top-section">
                  <div className="col product-image-section">
                    <section className="bc-product-single__top">
                      <ProductImages
                        images={images}
                        setReviewImage={setReviewImage}
                      />
                    </section>
                    {videos?.length > 0 ? (
                      <VideoSection videos={videos} />
                    ) : null}
                  </div>
                  <div className="col product-detail-section">
                    <div className="bc-product-single__meta">
                      <h2 className="product-brand-name">
                        {brand_url ? (
                          <Link to={brand_url}>{brand_name}</Link>
                        ) : (
                          brand_name
                        )}
                      </h2>
                      <h1 className="bc-product__title">{name}</h1>
                      <ReviewSection
                        reviewsCount={reviews_count}
                        reviews_rating_sum={reviews_rating_sum}
                        images={reviewImage}
                        bigcommerce_id={product_id}
                        name={name}
                        reviewSectionRef={reviewSectionRef}
                        setActivateReview={setActivateReview}
                      />
                      {user && <QuestionSection name={name} />}

                      {!isCustomerRestricted ? (
                        <Price
                          sale_price={priceSku["sale_price"]}
                          price={priceSku["base_price"]}
                          retail_price={priceSku["retail_price"]}
                          currentUser={currentLoginUser}
                        />
                      ) : null}
                      <ProductInfo
                        sku={priceSku["sku"]}
                        upc={upc}
                        availability={availability?.status}
                        order_quantity_minimum={order_quantity_minimum}
                        order_quantity_maximum={order_quantity_maximum}
                        weight={weight?.value}
                        weightUnit={weight?.unit}
                        bulk_pricing_rules={bulk_pricing_rules}
                        customFields={custom_fields}
                      />
                      {!isCustomerRestricted ? (
                        <PriceSkuContext.Provider value={contextValue}>
                          <OptionForm
                            min_purchase_qty={order_quantity_minimum}
                            max_purchase_qty={order_quantity_maximum}
                            data={product_variants}
                            bigcommerce_id={product_id}
                            isLoading={isLoading}
                            lineItemsLength={lineItemsLength}
                            availability={availability}
                          />
                        </PriceSkuContext.Provider>
                      ) : null}
                    </div>
                    <div className="social-share-links">
                      <div>
                        <FacebookShareButton url={location.href}>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </FacebookShareButton>
                      </div>
                      <div>
                        <PinterestShareButton
                          url={location.href}
                          media={"Product"}
                          description={description}
                        >
                          <FontAwesomeIcon icon={faPinterest} />
                        </PinterestShareButton>
                      </div>
                      <div>
                        <TwitterShareButton
                          url={location.href}
                          title={"Check out this amazing product"}
                        >
                          <FontAwesomeIcon icon={faTwitter} />
                        </TwitterShareButton>
                      </div>
                      <div>
                        <EmailShareButton
                          url={location.href}
                          subject={"This is the product"}
                          body={"Check out this amazing product"}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </EmailShareButton>
                      </div>
                      <div>
                        <button>
                          <FontAwesomeIcon
                            icon={faPrint}
                            onClick={handlePrint}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div ref={reviewSectionRef}>
                  <DescriptionSection
                    description={description}
                    warranty={warranty}
                    productId={product_id}
                    activateReview={activateReview}
                    setActivateReview={setActivateReview}
                  />
                </div>

                <RealtedProducts relatedProducts={related_products} />

                <ProductsList
                  title={"Popular Products"}
                  subTitle={"Popular products of the Month"}
                  products={popularProducts}
                  disableBg={true}
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export const Head = ({ pageContext }) => {
  return (
    <>
      <title>{pageContext.pageTitle}</title>
      <meta name="description" content={pageContext.metaDescription} />
    </>
  );
};

export default ProductDetailsUnregisterRoute;
